export default class ScreenFinder {

  constructor($vm, item) {
    this.$vm = $vm;
    this.item = item;
  }

  get connectorId() {
    return this?.item?.connector?.id || this?.item?.connector_id || this?.item?.id || null;
  }

  get connector() {
    let cid = this.connectorId;
    if (!cid) return null;
    return (this.$vm.$store.getters["dashboard/connectorList"] || []).find(
      ({ id }) => parseInt(id) == parseInt(cid)
    ) || this.item || null;
  }

  get isModel() {
    return this?.connector?.base_model || false;
  }

  get device() {
    return this?.item?.connector || this?.item?.connector_id ? this.item : null;
  }

  get link() {
    const connector = this.connector;
    const device = this.device;
    const screen = this.screen;
    if (!connector) return "";
    if (this.isModel) {
      return device
        ? `/dashboard/edit/connector/${connector.id}/device/${device.id}`
        : `/dashboard/edit/connector/${connector.id}`;
    } else {
      return device && device.screen_id
        ? `/dashboard/equipment/${connector.id}/${this.item.screen_id}?device_id=${device.id}`
        : connector &&
          screen?.id &&
          parseInt(connector.screen_id) == parseInt(screen?.id)
          ? `/dashboard/equipment/${connector.id}`
          : `/dashboard/equipment/${connector.id}/${screen?.id}`;
    }
  }

  get screen() {
    const connector = this.connector;
    const device = this.device;
    let entry = null;
    if (device || connector) {
      let sid = device?.screen_id || connector?.screen_id || "";
      entry = this.$vm.$store.getters["dashboard/screens"].find((i) =>
        sid ? parseInt(i.id) == parseInt(sid) : i.default
      );
    } else if (this?.item?.path !== undefined) {
      entry = this.item;
    }
    return entry;
  }
}
